<template>
  <scroll-container
    class="flex justify-center bg-white"
    style="width: 100%"
    containerInnerStyle="display: flex; flex-direction: row; justify-content: center; align-items: flex-start; width: 100%;"
  >
    <div
      class="lead-form-container pt-8 md:px-12 w-full max-w-3xl flex flex-col justify-start items-center bg-white"
    >
      <!-- FORM HAS BEEN SUBMITTED -->
      <div class="success-container flex flex-col justify-center items-center" v-if="submitted">
        <font-awesome-icon icon="circle-check" class="check !h-28 !w-28 text-matcha-500 mb-12" />
        <h1 class="mb-4">{{ l('Submission Successful') }}</h1>
        <div class="text-lg">
          {{ l('Thank you for your submission! We will get back to you as soon as possible') }}.
        </div>
      </div>

      <!-- FORM IS LOADING -->
      <spinner v-else-if="submitting" :loading="1" size="2em" />

      <!-- FORM BODY -->
      <div
        v-else-if="leadRotationId"
        class="lead-form-body flex flex-col justify-center items-center w-full"
      >
        <img v-if="companyLogo" :src="companyLogo" style="max-width: 300px; max-height: 150px" />
        <div v-else class="text-4xl font-bold">{{ companyName }}</div>
        <div class="lead-form-title text-2xl font-bold my-8 text-center">{{ leadFormName }}</div>
        <!-- default form fields -->
        <LeadFormContent
          v-if="form"
          :aoFields="form.aoFields"
          :aoFieldOptions="form.aoFieldOptions"
          :formFields="defaultFormFields"
          :oForm="form.oForm"
          @submit="(payload) => onSubmitLeadForm(form.lead_rotation_id, signature, payload)"
        />
      </div>

      <!-- FORM NOT FOUND -->
      <div v-else class="error-message text-center">
        <h3 class="mb-3">{{ l('Could not find form') }}</h3>
        <div>{{ l('This form is either not public or does not exist') }}.</div>
      </div>
    </div>
  </scroll-container>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import useTranslation from '@/components/composables/Translation'
import LeadFormContent from '@/components/leads/LeadFormContent.vue'
import useForm from '@/components/forms/Form'

const $route = useRoute()
const { l } = useTranslation()
const { submitted, form, submitting, defaultFormFields, onSubmitLeadForm, fetchLeadForm } =
  useForm()

const leadRotationId = ref()
const companyName = ref('')
const companyLogo = ref('')
const leadFormName = ref('')
const submissionUrl = ref('')
const oForm = ref()
const aoFields = ref([])
const aoFieldOptions = ref([])

const signature = computed(() => {
  if (submissionUrl.value) return submissionUrl.value.split('/')[4]
  return false
})

const mapData = (data) => {
  leadRotationId.value = data.lead_rotation_id
  companyName.value = data.company_name
  const file = data.company_logo_file_id
  if (file) companyLogo.value = `${import.meta.env.VITE_BASE_FILE_URL}file/view/${file}`
  leadFormName.value = data.lead_rotation_name
  submissionUrl.value = data.lead_rotation_url
  aoFields.value = data.aoFields
  aoFieldOptions.value = data.aoFieldOptions
  oForm.value = data.oForm
}

onMounted(async () => {
  await fetchLeadForm($route.params.id)
  mapData(form.value)
})
</script>

<style lang="scss" scoped></style>
